import { Box, Container, Text, Heading, SimpleGrid, VStack, GridItem, Grid } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import React from 'react';
import Layout from '../../../components/Layout';
import { MainServiceBanner } from '../../../components/shared';
import FlipCard from '../../../components/sections/FlipCards';
import SEO from '../../../components/seo';
import {
  ContactUsBannerWithForm,
  Hero,
  SubHero,
  SimpleImageCarousel,
  SlimProjectCarousel,
} from '../../../components/shared';
import { useMaintenanceService } from '../../../GraphQL/Queries/useMaintenanceService';

const Maintenance = ({ location }) => {
  const data = useMaintenanceService();

  const {
    acf_service_info,
    acf_text_block_title,
    acf_image_text_block,
    acf_quote,
    acf_services_block,
  } = data.serviceBy;

  const generalMaintenanceProjects = ['/images/gm-1.jpg', '/images/gm-2.jpg', '/images/gm-3.jpeg'];

  return (
    <Layout location={location}>
      <SEO title="Maintenance" />
      <Hero
        image={acf_service_info.background.sourceUrl}
        icon={acf_service_info.icon.sourceUrl}
        heroHeadline={acf_service_info.serviceTitle}
        heroCopy={acf_service_info.serviceShortDescription}
        ctaText="Get a quote"
        fullHeight
      />
      <SubHero heading={acf_text_block_title.title} body={acf_text_block_title.content} />

      <MainServiceBanner
        image={acf_image_text_block.blockList[0].image.sourceUrl}
        title={acf_image_text_block.blockList[0].title}
        noCta
        description={acf_image_text_block.blockList[0].content.replace(/<\/?[^>]+(>|$)/g, '')}
      />

      <Box
        pt={{ base: '0', lg: '5rem' }}
        pb={{ base: '2rem', lg: '5rem' }}
        height="auto"
        my={{ base: 'unset', lg: '5rem' }}
        zIndex="3"
      >
        <Container
          d="flex"
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent="space-between"
          mt={{ base: '-1rem', lg: 'unset' }}
        >
          <Box w={{ base: 'auto', lg: '400px' }} px={{ base: '2rem', lg: 'unset' }}>
            <Heading fontSize="35px" color="primary">
              {acf_image_text_block.blockList[1].title}
            </Heading>
            <VStack align="flex-start">
              <Text fontSize="18x" mt={{ base: '1rem', lg: '1rem' }} textStyle="p">
                {acf_image_text_block.blockList[1].content.replace(/<\/?[^>]+(>|$)/g, '')}
              </Text>
            </VStack>
          </Box>

          <SimpleGrid display={{ base: 'none', lg: 'grid' }} columns={3} spacing={2}>
            <FlipCard
              image1={acf_image_text_block.blockList[1].imagesFlipCard[0].imagesFlip[0].sourceUrl}
              image2={acf_image_text_block.blockList[1].imagesFlipCard[0].imagesFlip[1].sourceUrl}
            />
            <FlipCard
              image1={acf_image_text_block.blockList[1].imagesFlipCard[1].imagesFlip[0].sourceUrl}
              image2={acf_image_text_block.blockList[1].imagesFlipCard[1].imagesFlip[1].sourceUrl}
            />
            <FlipCard
              image1={acf_image_text_block.blockList[1].imagesFlipCard[2].imagesFlip[0].sourceUrl}
              image2={acf_image_text_block.blockList[1].imagesFlipCard[2].imagesFlip[1].sourceUrl}
            />
          </SimpleGrid>
        </Container>
      </Box>
      <Box display={{ base: 'block', lg: 'none' }}>
        <SimpleImageCarousel
          images={[
            acf_image_text_block.blockList[1].imagesFlipCard[0].imagesFlip[0].sourceUrl,
            acf_image_text_block.blockList[1].imagesFlipCard[0].imagesFlip[1].sourceUrl,
            acf_image_text_block.blockList[1].imagesFlipCard[1].imagesFlip[0].sourceUrl,
            acf_image_text_block.blockList[1].imagesFlipCard[1].imagesFlip[1].sourceUrl,
            acf_image_text_block.blockList[1].imagesFlipCard[2].imagesFlip[0].sourceUrl,
            acf_image_text_block.blockList[1].imagesFlipCard[2].imagesFlip[1].sourceUrl,
          ]}
        />
        <SlimProjectCarousel
          projects={generalMaintenanceProjects}
          heading={acf_image_text_block.blockList[2].title}
          body={acf_image_text_block.blockList[2].content.replace(/<\/?[^>]+(>|$)/g, '')}
        />
      </Box>

      <Box
        display={{ base: 'none', lg: 'block' }}
        bg="primary"
        pos="relative"
        py="5rem"
        color="white"
        height="auto"
        mt="-19rem"
        zIndex="-1"
        overflow="hidden"
      >
        <Box pos="absolute" bg="white" top="0" left="0" h="170px" w="100%" ml="34%" zIndex="-1" />
        <Container pos="relative" d="flex" justifyContent="space-between" alignItems="flex-end" zIndex="0">
          <Image
            zIndex="2"
            src={acf_image_text_block.blockList[2].image.sourceUrl}
            h="555px"
            w="680px"
            objectFit="cover"
          />

          <Box w="40%" mt="9rem" pl={{ lg: '1rem' }}>
            <Heading pb="1rem">{acf_image_text_block.blockList[2].title}</Heading>
            <Text textStyle="p">{acf_image_text_block.blockList[2].content.replace(/<\/?[^>]+(>|$)/g, '')}</Text>
          </Box>
        </Container>
      </Box>
      <Container
        d="flex"
        mt={{ base: '2rem', md: '8rem' }}
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'flex-start', lg: 'center' }}
      >
        <VStack
          justifyContent="center"
          alignItems="flex-start"
          w={{ base: '100%', lg: '515px' }}
          h="420px"
          m="0 auto"
          position="relative"
          flex="1"
          px={{ base: '2rem', lg: 'unset' }}
          pb={{ lg: '5rem' }}
        >
          <Image
            display={{ base: 'none', lg: 'unset' }}
            src="/icons/lines.svg"
            position="absolute"
            top="0"
            right="0"
            h="100%"
            w="100%"
            pr="6.5rem"
          />

          <Heading fontSize="35px" color="primary" pb="1rem" maxW={{ base: '225px', lg: 'unset' }}>
            {acf_services_block.servicesList[0].serviceTitle}
          </Heading>
          <Text w={{ base: '100%', lg: '70%' }} textStyle="p">
            {acf_services_block.servicesList[0].serviceDescription}.
          </Text>
        </VStack>
        <Image display={{ base: 'block', lg: 'none' }} src="/icons/line-mobile.svg" py="1rem" />
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(12, 1fr)"
          columnGap={{ base: 6, md: '2rem' }}
          rowGap={{ base: 4, md: 5 }}
          flex="1.2"
          ml={{ base: 'unset', lg: '-4rem' }}
          px={{ base: '1rem', lg: 'unset' }}
        >
          <GridItem colSpan={12}>
            <Image
              h="100%"
              boxShadow="15px -10px 0 #e2e2e4"
              src={acf_services_block.servicesList[0].serviceGallery[0].sourceUrl}
            />
          </GridItem>
          <GridItem colSpan={7} height={{ base: '145px', md: 'unset' }}>
            <Image
              h="100%"
              w="100%"
              objectFit="cover"
              boxShadow="10px -10px 0 #e2e2e4"
              src={acf_services_block.servicesList[0].serviceGallery[1].sourceUrl}
            />
          </GridItem>
          <GridItem colSpan={5} height={{ base: '145px', md: 'unset' }}>
            <Image
              h="100%"
              w="100%"
              objectFit="cover"
              colSpan={2}
              boxShadow="10px -10px 0 #e2e2e4"
              src={acf_services_block.servicesList[0].serviceGallery[2].sourceUrl}
            />
          </GridItem>
        </Grid>
      </Container>

      <Box my={{ md: '15rem' }} />
      {/* Client Section  - START -*/}

      {/* <Box
        backgroundImage="linear-gradient(to bottom, #fff, #e9e9e9);"
        mb={{ md: '13rem' }}
        mt={{ base: '-4rem', md: 'unset' }}
      >
        <Container pt={{ base: '0', lg: '5rem' }} pb={{ base: '2rem' }} pl={{ base: '2rem', lg: 'unset' }}>
          <Heading
            textStyle="servicePageHeading"
            color="primary"
            mb="1rem"
            pb=".5rem"
            borderBottom="4px"
            borderColor="primary"
          >
            Clients
          </Heading>
          <SimpleGrid columns={{ base: 2, md: 6 }} spacing="4" pr={{ base: '2rem', md: 'unset' }}>
            <Image src="/images/zrs.png" w="225px" h="150px" objectFit="contain" />
            <Image src="/images/advernir.png" w="225px" h="150px" objectFit="contain" />
            <Image src="/images/highmark.png" w="225px" h="150px" objectFit="contain" />
            <Image src="/images/baron.png" w="225px" h="150px" objectFit="contain" />
            <Image src="/images/greystar.png" w="225px" h="150px" objectFit="contain" />
            <Image src="/images/gables.png" w="225px" h="150px" objectFit="contain" />
          </SimpleGrid>
        </Container>
      </Box> */}

      {/* Client Section  - END -*/}

      <ContactUsBannerWithForm
        isCommercial
        lightBackground
        title={acf_quote.titleQuoteSection}
        image={acf_quote.backgroundImage.sourceUrl}
        description={acf_quote.descriptionQuote}
      />
    </Layout>
  );
};

export default Maintenance;
