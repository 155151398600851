import React, { useState, useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';
import { Image } from '@chakra-ui/image';

export default function FlipCards({ image1, image2 }) {
  const [isFlipped, setIsFlipped] = useState(false);
  useEffect(() => {
    let id = setInterval(() => {
      setIsFlipped(!isFlipped);
    }, 3000);

    return () => {
      clearInterval(id);
    };
  }, [isFlipped]);
  return (
    <>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <Image src={image1} />
        <Image src={image2} />
      </ReactCardFlip>
    </>
  );
}
